*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:root {
    --main-light-color: #FF81AE;
    --main-dark-color: #ff2e77;
    --main-opacity-color: #ff81ad2d;
    --main-font-size: 0.9rem;
}

.profilecard {
    /* background-color: red; */
    background-color: #fff;
    border-radius: 10px;
    /* padding: 1rem; */
    display: flex;
    flex-direction: column;

    width: 20rem;
}
.profilecard .upper-profile {
    background-image: url("../../assets/images/user-background2.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 5rem 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.user-profile-div {
    width: 100%;
    display: flex;
    justify-content: center;
    transform: translateY(-5.5rem);
    
}



/* /////////////////////////////////////////////////////// */
.user-main-avatar3  {
    height: 3rem;
    width: 3rem;
    padding: 5.5rem;
    border-radius: 50%;
    border: 2px solid #fff;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.user-main-avatar2  {
    height: 3rem;
    width: 3rem;
    padding: 4.8rem;
    border-radius: 50%;
    border: 2px solid #fff;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.user-main-avatar {
    height: 3rem;
    width: 3rem;
    padding: 4rem;
    background-image: url("../../assets/images/user-avatar4.png");
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    /* border: 5px solid var(--main-light-color); */
    border: 2px solid #fff;
    background-color: #fcd5ff;
    
}
/* /////////////////////////////////////////////////////// */




.lower-profile {
    transform: translateY(-1rem);
    margin-top: -5rem;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.lower-profile .top {
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.user-location,
.user-profession {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9rem;
    color: #333;
    text-transform: capitalize;
}

.user-location-icon,
.user-profession-icon {
    color: var(--main-light-color);
}
.top .user-name {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
}
.hhh {
    color: var(--main-light-color);

}

.user-bio p {
    font-size: 0.8rem;
    padding:  1rem 2rem;
    color: #333;
}
.user-email {
    background-color: var(--main-opacity-color);
    font-size: 0.8rem;
    padding: 0.1rem 1rem;
    border-radius: 5px;
}
.user-profile-social {
    margin-top: 1rem;
    width: 90%;
    justify-content: space-evenly;
    display: flex;
    gap: 1rem;
}
.user-profile-social a {
    border-radius: 20px !important;
    text-decoration: none;
    padding: 0.4rem 0.6rem;
    /* color: #fff; */
    
}

.user-profile-github,
.user-profile-linkedin {
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    border-radius: 5px;
    font-size: 0.9rem;
    cursor: pointer;
}
.user-profile-github {
    border: 2px solid var(--main-light-color);
    
}
.user-profile-linkedin {
    background-color: var(--main-light-color);
    border: 2px solid var(--main-light-color);
    
}
.user-profile-linkedin a {
    color: #fff;

}
.user-profile-github a {
    color: var(--main-light-color);
    
}
.user-profile-github:hover {
    transition: all 0.2s ease-out;
    background-color: var(--main-light-color);
    
}
.user-profile-github:hover a{
    transition: all 0.2s ease-out;
    color: #fff;
}
.user-profile-linkedin:hover {
    transition: all 0.2s ease-out;
    background-color: transparent;
    
}
.user-profile-linkedin:hover a{
    transition: all 0.2s ease-out;
    color: var(--main-light-color);
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,800;1,700&display=swap');
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
:root {
    --main-light-color: #FF81AE;
    --main-dark-color: #ff2e77;
    --main-font-size: 0.9rem;
}

.scholarship {
    display: flex;
    flex-direction: row;
    margin-top: 10rem !important;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    width: 1270px;
    flex-wrap: wrap;
    margin-bottom: 10rem;
}

.heading-scholarship {
    /* background-color: rgb(255, 223, 223); */
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 2rem;
    padding-right: 1rem;
}
.heading-scholarship .bottom-description {
    /* background-color: red; */
    font-family: 'Montserrat', sans-serif;

    color: #41265d;
    font-size: var(--main-font-size);
}
.overlay-donate {
    position: absolute;
    font-size: 6rem;
    z-index: 2;
    color: #ff81ad2d;
    font-weight: 800;
    text-transform: capitalize;
    transform: translateY(-2.8rem);
    letter-spacing: 3px;
}

.main-scholarship-heading {
    position: relative;
    z-index: 3;
    font-size: 3rem;
    text-transform: capitalize;
    line-height: 1.1;
    /* font-weight: 700; */
    /* background-color: red; */
}
.main-scholarship-heading p {
    font-family: 'Montserrat', sans-serif;
    /* background: linear-gradient(to right, #41265d, #742dbb); */
    background: linear-gradient(to right, var(--main-dark-color), var(--main-light-color));
    font-weight: 800;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.scholarship-banner {
    width: 44%;
    display: flex;
}
.scholarship-banner img{
    width: 100% ;
    height: auto;
    /* opacity: 0.9; */
}
.checkout {
    display: flex;
    align-items: flex-start;
    /* background-color: red; */
}
.checkout-button {
    background: linear-gradient(70deg, var(--main-dark-color), var(--main-light-color));
    box-shadow: 5px 2px 8px rgba(0, 0, 0, 0.12);
    border-radius: 30px;
    display: flex;
    padding: 0.4rem 1rem;
    padding-right: 0.4rem !important;
    cursor: pointer;
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    gap: 1rem;
}
.checkout-button:focus {
    outline: none;
}
.checkout-button:hover {
    box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.221);
    transform: translateY(-2px);
}
.checkout-button .purple-circle {
    background-color: #333;
    /* box-shadow: inset 0 0 0 0.5px rgba(0, 0, 0, 0.1); */
    padding: 0.6rem 1.2rem;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.arrow-icon {
    color: #fff;
    font-size: 1.2rem;
}

.donate-btn {
    display: flex;
    align-items: center;
}
.checkout-btn-text {
    transform: translateY(0.4rem);
    margin-left: 0.5rem;
    color: #fff;
    font-size: 1rem;

}
































/* //////////////////////////////////////////////////////// */


.scholarship-form {
    padding: 1rem;
    padding-bottom: 10rem;
    display: flex;
    margin: auto;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.scholarship-form .scholarship-form-desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: yellow; */
    margin-bottom: 2rem;
}
.scholarship-form .scholarship-form-desc p:nth-child(1) {
    font-size: 2.2rem;
    font-weight: 800;
    color: var(--main-dark-color);
}
.scholarship-form .scholarship-form-desc p:nth-child(2) {
    color: #333;
    font-size: 0.9rem;
}
.scholarship-form .contactFormContainer form{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: green; */
    padding: 3rem 2rem;
    border-radius: 10px;
    border: 1px solid var(--main-light-color);
  }

.scholarship-form .contactFormContainer form label input, .scholarship-form .contactFormContainer form label textarea {
    border: 1px solid transparent ;
    border: none;
    padding: 1rem;
    width: 600px;
    outline: none;
    margin-bottom: 1rem;
    font-size: 1rem;
    color: var(--main-dark-color) ;
    font-family: 'sans-serif', 'Poppins';
    /* background-color: transparent !important; */
    background-color: #ffd6e582;
    
}
.scholarship-form .contactFormContainer form .name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 600px;
    gap: 1rem;
    
}
.scholarship-form .contactFormContainer form .name label {
    width: 100%;
    
}
.scholarship-form .contactFormContainer form .name label input {
    width: 100%;
}
.scholarship-form .contactFormContainer form label input::placeholder, .contactFormContainer form label textarea::placeholder {
    color: var(--main-dark-color);
    font-size: 0.9rem;
    
  }
  .scholarship-form .contactFormContainer form label input:focus , .scholarship-form .contactFormContainer form label textarea:focus {
    border: 1px solid var(--main-dark-color);
  }
  
  .scholarship-form .contactFormContainer button {
    width: fit-content;
    padding: 0.6rem 2rem;
    font-size: 1rem;
    border: none;
    outline: none;
    cursor: pointer;
    color: #fff;
    /* background-color: var(--main-light-color); */
    background: linear-gradient(to right, var(--main-dark-color), var(--main-light-color));
    border-radius: 5px;
    text-transform: uppercase;
    font-family: 'sans-serif', 'Poppins';
    transition: all 0.2s ease-in-out;
    
  }
  .scholarship-form .contactFormContainer button:hover {
    transition: all 0.3s ease-in-out;
    color: #fff;
    background-color: var(--main-dark-color);
  
  }
  
  
  @media (max-width: 720px)  {
    .scholarship-form .contactFormContainer form {
      width: 100%;
    }
    .scholarship-form .contactFormContainer form .name {
      flex-direction: column;
      align-items: center;
      gap: 0;
      justify-content: center;
      margin: auto;
      width: 100%;
      
  }
    .scholarship-form .contactFormContainer form .rowdivv label input{
      width: 342px !important;
    }
    .scholarship-form .contactFormContainer form .rowdivv label:nth-child(1) input {
      margin-right: 0;
      width: 342px !important;
    }
    .scholarship-form .contactFormContainer form label input, .scholarship-form .contactFormContainer form label textarea{
      padding: 0.8rem;
      margin-bottom: 0.8rem;
      font-size: 1rem;
    }
    .scholarship-form .contactFormContainer button {
      padding: 0.6rem 1.6rem;
      font-size: 0.9rem;
    }
  }
  @media (max-width: 830px)  {
    .scholarship-form .contactFormContainer form .rowdivv label:nth-child(1) input {
      margin-right: 0;
    }
    .scholarship-form .contactFormContainer form .rowdivv {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .scholarship-form .contactFormContainer form label input, .scholarship-form .contactFormContainer form label textarea {
      width: 342px !important;
    }
    
  }
  @media (max-width: 370px)  {
    .scholarship-form .contactFormContainer form .rowdivv label input{
      width: 242px !important;
    }
    .scholarship-form .contactFormContainer form .rowdivv label:nth-child(1) input {
      margin-right: 0;
      width: 242px !important;
    }
    .scholarship-form .contactFormContainer form label input, .scholarship-form .contactFormContainer form label textarea {
      width: 242px !important;
    }
    
  }
  










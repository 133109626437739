:root {
    --main-light-color: #FF81AE;
    --main-dark-color: #ff2e77;
    --main-opacity-color: #ff81ad2d;
    --main-font-size: 0.9rem;
}

.internship-card{
    margin-top: 10px;
    width: 200px;
    width: 95%;
    display: flex;
    flex-direction: column;
    background-color: #fff !important;
    border-radius: 10px;
    /* box-shadow: 0 0 10px rgb(185, 185, 185); */
    /* border: 2px solid rgb(236, 236, 236); */
    box-shadow: 0 0 20px rgb(225, 225, 225);    flex-wrap: wrap;
    padding: 1rem 2rem;
    justify-content: space-between;
}
.internship-card:hover {
    /* transform: translateY(-0.3rem); */
    scale: 103%;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0 15px rgb(212, 212, 212);
    /* box-shadow: -6px 6px 15px rgb(206, 206, 206); */}
.internship-name,
.internship-position,
.internship-location,
.internship-start,
.internship-end {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
}
.internship-location,
.internship-start,
.internship-end {
    color: rgb(146, 146, 146);

}
.internship-position {
    font-size: 1.1rem;
    font-weight: 600;
}
.internship-name {
    font-size: 0.9rem;
}

.internship-card .middle {
    padding: 1rem 0;
}
.internship-card .middle .internship-responsibility p:nth-child(1),
.internship-card .middle .internship-qualification p:nth-child(1){
    font-size: 0.9rem;
    font-weight: 500;
}
.internship-card .middle .internship-responsibility p:nth-child(2),
.internship-card .middle .internship-qualification p:nth-child(2) {
    font-size: 0.9rem;
    line-height: 1.2;

}




.internship-card .bottom {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    border-top: 1px solid rgb(232, 232, 232);
}
.internship-card .bottom .left {
    display: flex;
    gap: 1rem;
}
.internship-working,
.internship-compensation {
    background-color: rgb(231, 231, 231);
    font-size: 0.8rem;
    padding: 0.3rem 0.8rem;
    border-radius: 20px;
}
.internship-card .bottom .internship-bookmark {
    display: flex;
    align-items: center;
}

.internship-icon {
    margin-right: 0.3rem;
    color: var(--main-dark-color);
}




/* ============================================== */
.internship-btn {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}
.appy-btn {
    background-color: var(--main-light-color);
    padding: 0.4rem 1rem;
    border-radius: 5px;
    font-size: 0.9rem;
    color: var(--main-dark-color);
    cursor: pointer;
}
.internship-bookmark {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0.4rem 1rem;
    border-radius: 5px;
    font-size: 0.9rem;
    background-color: var(--main-light-color);
}
.internship-bookmark:hover, .appy-btn:hover {
    transition: all 0.2s linear;
    background-color: #ff74a5;
}
.internship-bookmark a {
    display: flex;
    align-items: center;
    margin-right: 0.3rem;
}
.internship-bookmark p {
    color: #fff !important;
}
.bookmark-icon {
    color: #fff !important;
    transform: translateY(0.1rem);
}